<template>
  <VDialog
    content-class="cross__dialog"
    v-model="visibility"
    max-width="500px"
    :persistent="isChanged"
    @click:outside="showConfirmDialog"
    scrollable
  >
    <VCard :height="$vuetify.breakpoint.smAndDown ? '500' : ''">
      <div class="cross__inner">
        <VBtn fab small color="white" elevation="0" @click="showConfirmDialog">
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </div>
      <VCardTitle>
        <span class="font-20" :class="{ 'text-body-1': $vuetify.breakpoint.xs }">{{
          `${$t("form.add")}  ${$t("users.client")}а`
        }}</span>
      </VCardTitle>
      <VDivider class="mb-3" />
      <VCardText>
        <VContainer class="py-0">
          <VRow class="align-center mt-3 justify-center">
            <VCol cols="12" md="8" class="text-center">
              <label for="customer_photo" class="cursor-pointer customer-photo-label">
                <VIcon size="70" v-if="!photoPreview">mdi-account-circle</VIcon>
                <img :src="photoPreview" alt="" width="80" v-else />
              </label>
              <VFileInput
                id="customer_photo"
                class="d-none"
                v-model="customer.photo"
                accept="image/*"
                @change="makePreview"
              /><br />
              <VBtn
                v-if="customer.photo"
                @click="
                  customer.photo = null;
                  photoPreview = null;
                "
                depressed
                class="br-10"
              >
                {{$t('form.btn.delete')}}
              </VBtn>
            </VCol>
            <VCol cols="12" md="8" class="py-0">
              <VTextField
                class="br-10"
                :label="$t('form.name')"
                dense
                color="green"
                outlined
                v-model="customer.name"
                :error-messages="nameErrors"
                v-on:keypress="noEngText($event)"
                @paste="noEngTextPasted($event)"
                :hint="onEngText ? '' : $t('form.hint.NameNoEngText')"
                @input="noEngText($event,false)"
              />
            </VCol>
            <VCol cols="12" md="8" class="py-0">
              <VTextField
                class="br-10"
                :label="$t('form.surname')"
                dense
                outlined
                color="green"
                v-model="customer.surname"
                :error-messages="surnameErrors"
                v-on:keypress="noEngText($event)"
                @paste="noEngTextPasted($event)"
                :hint="onEngText ? '' :  $t('form.hint.SurnameNoEngText')"
                @input="noEngText($event,false)"
              />
            </VCol>
            <VCol cols="12" md="8" class="py-0">
              <VTextField
                class="br-10 inputPhone"
               :label="$t('form.phone_number')"
                dense
                color="green"
                prefix="+38"
                v-on:keypress="onlyNumber($event)"
                :maxLength="20"
                outlined
                v-model="customer.phoneNumber"
                :error-messages="phoneErrors"
                @paste="noEngTextPasted($event, true)"
                autocomplete="new-password"
              >
              </VTextField>
            </VCol>
            <VCol cols="12" md="8" class="py-0">
              <VTextField
                class="br-10"
                :label="$t('form.email')"
                dense
                outlined
                v-model="customer.email"
                autocomplete="off"
                :error-messages="emailErrors"
                color="green"
              />
            </VCol>
            <VCol cols="12" md="8" class="py-0" v-if="accessRole">
              <v-autocomplete
                class="br-10"
                :items="depots"
                :item-value="'uuid'"
                :item-text="'name'"
                outlined
                :label="$t('form.storage')"
                :no-data-text="$t('table.noData')"
                dense
                v-model="customer.deport_uuid"
                :error-messages="uuidErrors"
                small-chips
                deletable-chips
              />
            </VCol>
            <VCol cols="12" md="8" class="py-0">
              <VTextField
                class="br-10"
                autocomplete="off"
                :label="$t('form.company')"
                dense
                outlined
                v-model="customer.company"
                color="green"
              />
            </VCol>
            <VCol cols="12" md="8" class="py-0">
              <VMenu
                ref="datePikerDialog"
                v-model="datePikerDialog"
                :close-on-content-click="false"
                :return-value.sync="customer.date_crm_end"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <VTextField
                    class="br-10"
                    v-model="customer.date_crm_end"
                    :label="$t('form.crm_end_date')"
                    readonly
                    dense
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    color="green"
                    
                  ></VTextField>
                </template>
                <VDatePicker
                  v-model="customer.date_crm_end"
                  no-title
                  scrollable
                  :locale="currentLanguage.code"
                  :min="new Date().toISOString().substr(0, 10)"
                >
                  <VSpacer></VSpacer>
                  <VBtn text color="primary" @click="datePikerDialog = false">
                    Cancel
                  </VBtn>
                  <VBtn
                    text
                    color="primary"
                    @click="$refs.datePikerDialog.save(customer.date_crm_end)"
                  >
                    OK
                  </VBtn>
                </VDatePicker>
              </VMenu>
            </VCol>
            <VCol cols="12" md="8" class="py-0">
              <form autocomplete="off">
                <VTextField
                  class="br-10"
                 :label="$t('form.password')"
                  dense
                  autocomplete="off"
                  outlined
                  v-model="customer.password"
                  :type="passwordVisible ? 'text' : 'password'"
                  :append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="passwordVisible = !passwordVisible"
                  :error-messages="passwordErrors"
                  color="green"
                />
              </form>
            </VCol>
          </VRow>
        </VContainer>
      </VCardText>
      <VCardActions>
        <VContainer>
          <VRow class="my-0 py-0">
            <VCol cols="6" class="my-0 py-0">
              <VBtn
                @click="onClose"
                block
                class="w-100 br-10 text-transform-none"
                dark
                :class="{ 'text-caption': $vuetify.breakpoint.xs }"
              >
               {{$t('btn.cancel')}}
              </VBtn>
            </VCol>
            <VCol cols="6" class="my-0 py-0">
              <VBtn
                class="text-white text-transform-none br-10 w-100"
                :class="{ 'success-bg': isChanged, 'text-caption': $vuetify.breakpoint.xs }"
                block
                :disabled="!isChanged"
                @click="onCreate"
              >
                {{$t('btn.create')}}
              </VBtn>
            </VCol>
          </VRow>
        </VContainer>
      </VCardActions>
    </VCard>
    <ConfirmLeftDialog
      :visible="visibleConfirm"
      @close="visibleConfirm = false"
      @delete="onClose"
    />
  </VDialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email, requiredIf } from "vuelidate/lib/validators";
import ConfirmLeftDialog from "@/components/dialog/ConfirmLeftDialog.vue";
import language from "../../mixins/language";
import dialogMixin from "../../mixins/dialogMixin";
import passwordMask from "../../validators/passwordMask";
import notifications from "../../mixins/notifications";
import usersService from "../../services/request/users/usersService";
import EventBus from "../../events/EventBus";
import depotService from "../../services/request/depot/depotService";
import user from "../../mixins/user";
import phoneMask from "@/validators/phoneMask";
import { noEngText, onlyNumber, noEngTextPasted } from "@/mixins/helpers";
import { mapGetters } from "vuex";
import noEngTextValidator from "@/validators/noEngText";

export default {
  mixins: [language, dialogMixin, validationMixin, notifications, user],
  name: "AddClientDialog",
  validations: {
    customer: {
      name: { required, noEngTextValidator },
      surname: { required, noEngTextValidator },
      deport_uuid: {
        // eslint-disable-next-line func-names
        required: requiredIf(function() {
          return this.accessRole;
        })
      },
      phoneNumber: { phoneMask, required },
      email: { email, required },
      password: { required, passwordMask }
    }
  },
  components: {
    ConfirmLeftDialog
  },
  data: () => ({
    onEngText: true,
    customer: {
      name: "",
      surname: "",
      phoneNumber: "",
      company: "",
      uuid_crm_apikey: "",
      email: "",
      password: "",
      deport_uuid: "",
      photo: null,
      date_crm_end: null
    },
    visibleConfirm: false,
    depots: [],
    datePikerDialog: false,
    accessRole: false,
    photoPreview: null,
    passwordVisible: false,
    isChanged: false
  }),
  watch: {
    customer: {
      deep: true,
      handler() {
        const values = Object.values(this.customer);
        if (values.filter(item => item).length) {
          this.isChanged = true;
        } else {
          this.isChanged = false;
        }
      }
    }
  },
  async mounted() {
    this.updateRoleInfo();
    try {
      this.depots = await depotService.getDepotsList();
    } catch (e) {
      this.setErrorNotification(e);
    }
  },
  methods: {
    noEngText,
    onlyNumber,
    noEngTextPasted,
    showConfirmDialog() {
      if (this.isChanged) {
        this.visibleConfirm = true;
      } else {
        this.onClose();
      }
    },
    onClose() {
      this.customer = [];
      this.visibility = false;
    },
    updateRoleInfo() {
      if (this.permissions.can_create_user_deport) {
        this.accessRole = true;
        // this.validations.user.deport_uuid.required = true;
      }
    },
    async onCreate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          const formData = new FormData();
          formData.append("name", this.customer.name);
          formData.append("surname", this.customer.surname);
          formData.append("email", this.customer.email);
          formData.append("company", this.customer.company);
          formData.append("password", this.customer.password);
          if (this.accessRole) {
            formData.append("uuid_deport", this.customer.deport_uuid);
          }
          if (this.customer.date_crm_end) {
            formData.append("date_crm_end", this.customer.date_crm_end);
          }
          formData.append("type", this.$route.name === 'crm-users' ? 'crm' : 'client');
          formData.append("phone", `+38${this.customer.phoneNumber}`);
          if (this.customer.photo) {
            formData.append("image", this.customer.photo);
          }
          await usersService.addUser(formData);
          EventBus.$emit("added-user");
          this.$emit("resetFilter", true);
          this.visibility = false;
          this.$v.$reset();
          this.clearCustomerData();
          this.setSuccessNotification(this.$t('users.user_add_success'));
        } catch (e) {
          this.setErrorNotification(e);
        }
      }
    },
    clearCustomerData() {
      Object.keys(this.customer).forEach(key => {
        this.customer[key] = null;
      });
    },
    makePreview() {
      let photoPreview = null;
      const { photo } = this.customer;
      const reader = new FileReader();
      reader.readAsDataURL(photo);
      reader.onloadend = ev => {
        photoPreview = ev.target.result;
        this.photoPreview = photoPreview;
      };
    }
  },
  computed: {
       ...mapGetters(["currentLanguage"]),
    uuidErrors() {
      const errors = [];
      if (!this.$v.customer.deport_uuid.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.customer.deport_uuid.required && errors.push(this.$t('form.errors.StorageRequired'));
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.customer.name.$dirty) {
        return errors;
      }
      if (!this.$v.customer.name.noEngTextValidator) {
        errors.push(this.$t("form.hint.NameNoEngText"));
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.customer.name.required && errors.push(this.$t('form.errors.NameRequired'));
      return errors;
    },

    surnameErrors() {
      const errors = [];
      if (!this.$v.customer.surname.$dirty) {
        return errors;
      }
      if (!this.$v.customer.surname.noEngTextValidator) {
        errors.push(this.$t("form.hint.SurnameNoEngText"));
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.customer.surname.required &&
        errors.push(this.$t('form.errors.SurnameRequired'));
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.customer.phoneNumber.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.customer.phoneNumber.required &&
        errors.push(this.$t('form.errors.PhoneNumberRequired'));
      // eslint-disable-next-line no-unused-expressions
      !this.$v.customer.phoneNumber.phoneMask &&
        errors.push(this.$t('form.errors.PhoneNumberMustBeLike'));
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.customer.email.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.customer.email.required &&
        errors.push(this.$t('form.errors.EmailRequired'));
      // eslint-disable-next-line no-unused-expressions
      !this.$v.customer.email.email && errors.push(this.$t('form.errors.EmailMustBeValid'));
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.customer.password.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.customer.password.required &&
        errors.push(this.$t('form.errors.PasswordRequired'));
      // eslint-disable-next-line no-unused-expressions
      !this.$v.customer.password.passwordMask && errors.push(this.$t('form.errors.PasswordMustBeLike'));
      return errors;
    }
  }
};
</script>

<style scoped lang="scss">
.customer-photo-label:hover {
  i {
    opacity: 0.7;
  }
}
</style>
